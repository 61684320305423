@import '../../colors'
@import '../../_vars'

.ThirdPage
	width: 100vw
	background-color: white
	z-index: 1
	overflow: hidden
.thirdPageText
	font-size: 2em
	letter-spacing: 2.4px
	color: $PRIMARY_COLOR
	margin: 1px 5vw 1px
.textWrapper
	margin-top: 10vh
	margin-bottom: 5vh
	display: flex
	justify-content: center
	align-items: center
.firstText
	width: 400px
.secondText
	width: 400px
.centeringContainer
	display: flex
	justify-content: center
	align-items: center
.ImgLink
	width: 160px

.globe
	z-index: -1
	border: 2px solid $PRIMARY_COLOR
	border-radius: 50%
	height: 1000px
	left: 50%
	overflow: hidden
	position: relative
	top: 30%
	transform: translate(-50%, -50%)
	width: 1000px
	box-shadow: inset 0px 0px 20px 1px #009688
	img
		animation: rotation 30s linear 0s infinite
	path
		stroke: $PRIMARY_COLOR
		@keyframes rotation
			from
				transform: translateX(0)
			from
				transform: translateX(-50%)

@media screen and (max-width: $mobile-width)
	.ImgLink
		width: 100px
	.thirdPageText
		font-size: 1.4em
		margin: .3em 0px
	.textWrapper
		flex-direction: column
		margin-top: 5vh
	.globe
		height: 600px
	.ThirdPage
	.firstText
		width: 80vw
	.secondText
		width: 80vw
