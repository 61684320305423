@import '../../colors'
@import '../../_vars'

.SecondPage
	display: flex
	position: relative
	justify-content: center
	align-items: center
	background-image: linear-gradient(to bottom right,$ACCENT_COLOR_2, $PRIMARY_COLOR, $ACCENT_COLOR)
	z-index: 0
	padding: 5vw 2vw
	overflow: hidden
@mixin secondPageText
	font-size: 2em
	letter-spacing: 2.4px
	max-width: 600px
	color: white
	margin: 1px 5vw 1px
	@media screen and (max-width: $mobile-width)
		font-size: 1.5em
		max-width: 800px
.secondPageTextLeft
	@include secondPageText
	text-align: left
	margin-top: 9vh

.secondPageTextRight
	@include secondPageText
	text-align: right
	margin-top: -9vh

@media screen and (max-width: $mobile-width)
	.SecondPage
		padding: 13vh 0vw
		background-image: linear-gradient(to bottom right, $PRIMARY_COLOR, $ACCENT_COLOR)
		flex-direction: column
		align-content: flex-end
	.secondPageTextLeft
		margin-top: 0px
		margin-bottom: 8vh
	.secondPageTextRight
		margin-top: 8vh
	.SecondPage .ThreadLine
		transform: rotate(-10deg) !important
