@import '../../colors'
@import '../../_vars'

.Footer
	position: relative
	display: flex
	justify-content: space-around
	flex-direction: row
	background-color: $PRIMARY_COLOR
	width: 100vw
	z-index: 1
	color: white
	padding: 1em
	overflow: hidden

.leftFooter
	display: flex
	justify-content: center
	flex-direction: column
	padding-left: 1em

.fTitle
	display: flex
	align-items: center

.infoText
	margin-top: 1em
	margin-left: 1em
	margin-bottom: 1em
	line-height: 1.4em
	font-weight: 800
	a
		color: white

.rightFooter
	display: flex
	justify-content: center
	align-items: start
	flex-direction: column
	padding: 2em
	padding-top: 1em
	padding-left: 0em

.copyrightTag-m
	display: none

.insideRightFooter
	display: flex
	flex-direction: column
	label
		display: block
		font-size: 1.4em
		margin-bottom: .2em
	input
		margin-top: .2em
	input, button
		display: block
		padding: .5em
		border-radius: 8px
		border: none
		margin-bottom: .2em
		background-color: white
	button
		width: 10em
		font-weight: 800
		color: $PRIMARY_COLOR
		border: solid 1px white
	button:hover
		background-color: $PRIMARY_COLOR
		color: white
		cursor: pointer

.fTitle
	color: white
	letter-spacing: 0.3em
	margin-bottom: 10px
	margin-top: 10px
	font-size: 2em
	font-family: "Josefin Sans", sans-serif

@media screen and (max-width: $mobile-width)
	.fTitle
		font-size: 1.5em
		margin-left: -1em
	.rightFooter
		padding: 1em
	.Footer
		flex-direction: column
	.copyrightTag-d
		display: none
	.copyrightTag-m
		display: block
